import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import { apple } from "react-icons-kit/fa/apple";
import { windows } from "react-icons-kit/fa/windows";
import { linux } from "react-icons-kit/fa/linux";
import { android } from "react-icons-kit/fa/android";
import { spinner } from "react-icons-kit/fa/spinner";

import { openModal, closeModal } from "@redq/reuse-modal";
import Button from "../../../common/components/Button";
import RequestTrialContactForm from "../RequestTrialContactForm";
import { ContactFormWrapper } from "../RequestTrialContactForm/contactform.style";

const GIT_HUB_API =
  "https://api.github.com/repos/CloudPouch/CloudPouch.dev/releases/latest";
const GIT_HUB_URL = "https://github.com/CloudPouch/CloudPouch.dev/releases";

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <ContactFormWrapper>
    <RequestTrialContactForm />
  </ContactFormWrapper>
);

const TrialDownloadButton = ({ buttonStyle, className, title }) => {
  const [label, setLabel] = useState("TRY FOR FREE");
  const [icon, setIcon] = useState(spinner);
  const [isDownloadable, setDownloadble] = useState(false);
  const [osDetails, setOsDetails] = useState({
    name: "",
    icon: spinner,
    ext: "",
  });
  const [downloadUrl, setDownloadUrl] = useState(GIT_HUB_URL);

  useEffect(() => {
    const userAgent = typeof window !== "undefined" && navigator.userAgent;

    let details = {
      name: "Mac",
      icon: apple,
      ext: "dmg",
    };

    if (userAgent.includes("Macintosh")) {
      details.name = "Mac";
      details.icon = apple;
      details.ext = "dmg";
      setDownloadble(true);
    }

    if (userAgent.includes("Windows")) {
      details.name = "Windows";
      details.icon = windows;
      details.ext = "exe";
      setDownloadble(true);
    }

    if (userAgent.includes("Linux")) {
      details.name = "Linux";
      details.icon = linux;
      details.ext = "AppImage";
      setDownloadble(true);
    }

    if (userAgent.includes("iPhone")) {
      details.name = "iPhone";
      details.icon = apple;
      details.ext = "dmg";
      setDownloadble(false);
    }

    if (userAgent.includes("Android")) {
      details.name = "Android";
      details.icon = android;
      details.ext = "AppImage";
      setDownloadble(false);
    }

    setOsDetails(details);
  }, []);

  useEffect(() => {
    if (isDownloadable) {
      fetch(GIT_HUB_API, {
        method: "GET", // default, so we can ignore
      })
        .then((response) => response.json())
        .then((data) => {
          const assetUrls = data.assets.map((e) => e.browser_download_url);
          setDownloadUrl(assetUrls.filter((e) => e.endsWith(osDetails.ext)));
        })
        .catch((err) => {
          console.log(err.message);
        });

      setLabel(title || `Download Free trial for ${osDetails.name}`);
      setIcon(osDetails.icon);
    } else {
      setLabel(title || `Request Free trial for Desktop`);
    }
  }, [isDownloadable, osDetails, title]);

  const handleContactFormModal = () => {
    openModal({
      config: {
        className: "contact-form-modal",
        disableDragging: true,
        default: {
          width: "auto",
          height: "auto",
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  if (isDownloadable)
    return (
      <a
        href={downloadUrl}
        target="_blank"
        rel="noreferrer"
        className={className}
      >
        <Button
          {...buttonStyle}
          title={label}
          icon={<Icon icon={icon} size={24} />}
          iconPosition="right"
        />
      </a>
    );

  return (
    <Button
      {...buttonStyle}
      title={label}
      iconPosition="right"
      onClick={handleContactFormModal}
    />
  );
};

TrialDownloadButton.defaultProps = {
  buttonStyle: {
    type: "button",
    fontSize: "13px",
    fontWeight: "700",
    borderRadius: "4px",
    pl: "15px",
    pr: "15px",
    colors: "secondaryWithBg",
    minHeight: "auto",
    height: "40px",
  },
};

TrialDownloadButton.propTypes = {
  buttonStyle: PropTypes.object,
  title: PropTypes.string,
};

export default TrialDownloadButton;
