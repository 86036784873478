import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const SectionWrapper = styled.section`
  padding: 75px 0;
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 0;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  max-width: 320px;
  padding: 6px;
  margin: 0 auto;
  border-radius: 5px;
  background-color: ${themeGet("colors.inactiveField", "#f7f8fb")};

  button {
    border: 0;
    padding: 15px 26px;
    border-radius: 5px;
    color: rgba(15, 33, 55, 0.5);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    @media only screen and (max-width: 480px) {
      font-size: 15px;
    }

    &.active {
      color: ${themeGet("colors.headingColor", "#0f2137")};
      background-color: ${themeGet("colors.white", "#ffffff")};
      box-shadow: 0 3px 4px
        ${themeGet("colors.shadow", "rgba(38, 78, 118, 0.1)")};
    }
  }
`;

export const PricingArea = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 991px) {
    padding: 0 20px;
    justify-content: center;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 51px;
  margin-bottom: 30px;

  @media only screen and (max-width: 991px) {
    justify-content: center;
  }

  @media only screen and (max-width: 667px) {
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
`;

export const PricingCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(100% / 2 - 25px);
  max-width: 340px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 50px ${themeGet("colors.shadow", "rgba(38, 78, 118, 0.1)")};
  position: relative;
  @media only screen and (min-width: 1367px) {
    width: calc(33.33% - 2rem);
    margin-right: 1rem;
  }
  @media only screen and (max-width: 1366px) {
    width: calc(33.33% - 1rem);
    margin-right: 1rem;
  }
  @media only screen and (max-width: 991px) {
    width: calc(100% / 2 - 1rem);
    margin: 0.5rem;
  }
  @media only screen and (max-width: 667px) {
    width: 100%;
    margin-bottom: 1rem;
    padding: 10px;
  }

  .tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 109px;
    height: 30px;
    position: absolute;
    top: -10px;
    left: 37px;
    border-radius: 5px;
    color: ${themeGet("colors.white", "#ffffff")};
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    background-color: ${themeGet("colors.yellow", "#FFA740")};
    img {
      margin-right: 6px;
    }
  }

  .card-header {
    padding: 17px 20px;
    margin-bottom: 20px;
    @media only screen and (max-width: 480px) {
      padding: 15px 10px 10px;
    }
    h3 {
      color: ${themeGet("colors.headingColor", "#0F2137")};
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    p {
      color: ${themeGet("colors.textColor", "rgba(52, 61, 72, 0.8)")};
      font-size: 15px;
      margin: 0;
    }
  }

  .card-body {
    padding: 0 20px;
    margin-bottom: 25px;
    @media only screen and (max-width: 480px) {
      padding: 0 10px;
      margin-bottom: 30px;
    }
    ul {
      li {
        color: ${themeGet("colors.quoteText", "#343D48")};
        font-size: 16px;
        margin-bottom: 21px;
        i {
          color: ${themeGet("colors.primary", "#2563FF")};
          margin-right: 8px;
          svg {
            width: 20px;
            height: auto;
          }
        }
      }
    }
  }

  .card-footer {
    padding: 17px 30px;
    border-top: 1px solid #f3f4f5;
    text-align: center;
    @media only screen and (max-width: 480px) {
      padding: 30px 10px 20px;
    }
    strong {
      display: block;
      color: ${themeGet("colors.textColor", "rgba(52, 61, 72, 0.8)")};
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 27px;
      span {
        font-weight: 700;
        font-size: 36px;
        color: ${themeGet("colors.headingColor", "#0F2137")};
      }
    }
    .reusecore__button {
      border-radius: 5px;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .trail {
      margin-top: 22px;
      a {
        color: rgba(37, 99, 255, 0.9);
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
`;

export const PricingEnterpriseCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 100%;
  max-width: 780px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 50px ${themeGet("colors.shadow", "rgba(38, 78, 118, 0.1)")};
  position: relative;
`;

export default SectionWrapper;
