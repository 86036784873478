import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
// Import the responsive plugin, cloudinary & images map
import { AdvancedImage, placeholder, responsive } from "@cloudinary/react";
import { IMAGES } from "common/data/SaasModern";

import PartnerSectionWrapper from './partner.style';

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  author,
  textArea,
  imageArea,
  partnerImage
}) => {
  return (
    <PartnerSectionWrapper id="team">
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <AdvancedImage
              style={{maxWidth: '560px'}}
              cldImg={IMAGES.founderImage}
              plugins={[responsive({ steps: [200, 320, 360, 560] }), placeholder({ mode: 'blur'})]}
              alt="Paweł Zubkiewicz"
            />
          </Box>
          <Box {...col} {...textArea}>
            <Heading
              {...title}
              content="Hello. I'm Paweł 👋"
            />
            <Text
              {...description}
              content={<>
              <a href="https://aws.amazon.com/developer/community/heroes/pawel-zubkiewicz/" target='_blank'><b>AWS Hero</b></a>
              , architect & developer. Founder of the CloudPouch application.
              </>}
            />
            <Text
              {...description}
              content="I wrote CloudPouch because I needed a simple solution to understand costs in multiple accounts that I manage in my everyday work. 
              CloudPouch works locally on my machine, so it can be used with my clients' accounts, without special permissions and a hassle of multiple browser windows."
            />
            <Text
              {...description}
              content="I use it everyday to check current usage; find waste; and misconfigured resources; in order to optimize costs for my clients."
            />
            <Text
              {...author}
              content={<>
              <a href="https://www.linkedin.com/in/pawelzubkiewicz/" target='_blank'><b><i>Paweł Zubkiewicz</i></b></a>
              </>}
            />
            <Text
              {...author}
              content="Founder."
            />
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  author: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '38%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '56%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  author: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
  partnerImage: {
    maxWidth: '560px',
  }
};

export default PartnerSection;
