import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/components/Accordion';
import Container from 'common/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';

import FaqWrapper from './faq.style';


const CodeFont = styled.span`
  background: rgba(0,0,0,0.1);
  color: black;
  border-radius: 5px;
  max-width: 100%;
  font-size: 80%;
  padding: 0.3em 0.25em;
  font-family: "SF Mono", SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
`;

const FaqSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  title,
  description,
  buttonWrapper,
  button,
}) => {
  const Data = [
    {
      title: 'Which privileges are required to use this application?',
      description:(<>
        Most importantly, your IAM user must be authorized to perform <CodeFont>ce:GetCostAndUsage</CodeFont> action.<br />
        For Cost Insights other read-only privileges are also necessary, please see documentation <a href='https://github.com/CloudPouch/CloudPouch.dev#minimal-iam-user-privledges' target='_blank'>Minimal IAM User privileges</a>.
      </>),
    },
    {
      expend: true,
      title: 'Is CloudPouch going to incur extra costs on my AWS bill?',
      description:(<>
        Yes. CloudPouch uses AWS CostExplorer API to load or refresh charts and tables. AWS charges <CodeFont>$0.01</CodeFont> per request. To display costs on a single screen (particular AWS account + time period) CloudPouch needs data fetched by 2 requests.
      </>),
    },
    {
      title: 'How does it work? Is it secure?',
      description:(<>
        CloudPouch uses AWS SDK to connected to your AWS Account(s) and fetch cost data. There is noting in the middle. 
        AWS Credentials are taken from your <CodeFont>~/.aws/profiles</CodeFont> & <CodeFont>~/.aws/config</CodeFont> files. In principle it works similarly to AWS CLI command executed against Cost Explorer API (aws ce get-cost-and-usage). We are not sharing or storing your data anywhere. See next point.'
      </>),
    },
    {
      title: 'Does CloudPouch store my cost data anywhere?',
      description:
        'Your billing data is stored on your computer only. Since AWS Cost Explorer API incurs costs, once fetched data is stored and reused in order to save you from additional costs.',
    },
    {
      title: "I haven't received my license key, what to do?",
      description: (<>
       Check your SPAM folder. License key is sent from hello@cloudpouch.dev email address. If that doesn't help write an e-mail to us at <a href='mailto:hello@cloudpouch.dev'>hello@cloudpouch.dev</a>
      </>)
    },
    {
      title: "How do I cancel my subscription?",
      description:
        'In the email sent by Paddle.com (subject: "Welcome to CloudPouch...", from: "help@paddle.com") there is a link titled "review your subscription". Click on it and next click on "Cancel Subscription".',
    },
    {
      title: 'Where can I find you? Any Social Media?',
      description:(
      <>
        Check our Twitter account at <a href='https://twitter.com/CloudPouch' target='_blank'>@CloudPouch</a> and join our group on <a href='https://www.linkedin.com/groups/12588087/' target='_blank'>LinkedIn</a>.
      </>),
    },
    {
      title: 'I have a question, problem, feature request, suggestion?',
      description:(<>
        Don't hesitate to contact us. We're open to your ideas. Either write an e-mail at <a href='mailto:hello@cloudpouch.dev'>hello@cloudpouch.dev</a> or 
        visit our <a href='https://github.com/CloudPouch/CloudPouch.dev/issues' target='_blank'>GitHub Issues page</a> to create a ticket.
      </>)
      },
  ];

  return (
    <Box {...sectionWrapper} id="faq_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="FREQUENTLY ASK QUESTION" />
          <Heading {...secHeading} content="Want to ask something from us?" />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <FaqWrapper>
              <Accordion>
                <>
                  {Data.map((accordionItem, index) => (
                    <AccordionItem
                      className="accordion_item"
                      key={`accordion-${index}`}
                      expanded={accordionItem.expend}
                    >
                      <>
                        <AccordionTitle className="accordion_title">
                          <>
                            <Heading {...title} content={accordionItem.title} />
                            <IconWrapper>
                              <OpenIcon>
                                <Icon icon={minus} size={18} />
                              </OpenIcon>
                              <CloseIcon>
                                <Icon icon={plus} size={18} />
                              </CloseIcon>
                            </IconWrapper>
                          </>
                        </AccordionTitle>
                        <AccordionBody className="accordion_body">
                          <Text
                            {...description}
                            content={accordionItem.description}
                          />
                        </AccordionBody>
                      </>
                    </AccordionItem>
                  ))}
                </>
              </Accordion>
            </FaqWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

FaqSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

FaqSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '30px', '50px', '50px'],
    pb: ['60px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#7197d6',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  col: {
    width: ['100%', '100%', '75%', '75%'],
  },
  title: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: 0,
  },
  description: {
    fontSize: '15px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: 0,
  },
  buttonWrapper: {
    mt: '50px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default FaqSection;
