import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';

import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';

import BannerSection from 'containers/SaasModern/Banner';
import Navbar from 'containers/SaasModern/Navbar';
import WorkingProcessSection from 'containers/SaasModern/WorkingProcess';
import PricingPolicy from 'containers/SaasModern/PricingPolicy';
import PartnerSection from 'containers/SaasModern/Partner';
import FaqSection from 'containers/SaasModern/Faq';
// import TrialSection from 'containers/SaasModern/Trial';
import InfoSection from 'containers/SaasModern/Info';
import FeatureSection from 'containers/SaasModern/Feature';
import UpdateScreen from 'containers/SaasModern/UpdateScreen';
import ShoutoutTestimonials from 'containers/SaasModern/ShoutoutTestimonials';
import Footer from 'containers/SaasModern/Footer';
import Seo from 'components/seo';
import TrialSection from '../containers/SaasModern/Trial';
import TestimonialSection from '../containers/SaasModern/Testimonial';

const SaasModern = () => {
  const keywords = "AWS, billing, costs, optimization, FinOps, reduce, invoice, TCO, cloud economist".split(',');
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo title="CloudPouch | See. Understand. Reduce AWS costs." keywords={keywords}/>
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9998} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <WorkingProcessSection />
          <InfoSection />
          <FeatureSection />
          <UpdateScreen />
          <ShoutoutTestimonials />
          <PricingPolicy />
          <TestimonialSection />
          <TrialSection />
          <PartnerSection />
          <FaqSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default SaasModern;
