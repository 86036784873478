import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../common/components/Text';

const UnderDownloadButtonText = ({underButtonsText, className, color }) => {
  return (
    <Text {...underButtonsText} 
    content='✔️ 7-day free trial ✔️ No credit card required ✔️ Available for macOS, Linux and Windows' 
    className={className} 
    color={color}/>
  );
};

UnderDownloadButtonText.defaultProps = {
  underButtonsText: {
    fontSize: ['11px', '12px', '12px', '12px', '12px'],
    lineHeight: '1.75',
    mb: '0',
    mt: '5px',
    textAlign: 'center',
  },
};

UnderDownloadButtonText.propTypes = {
  underButtonsText: PropTypes.object,
};

export default UnderDownloadButtonText;
