import styled from 'styled-components';

export const ContactFormWrapper = styled.section`
  padding: 25px;
    linear-gradient(35deg, rgb(51, 70, 102) 0%, rgb(113, 151, 214) 100%);
  @media (min-width: 575px) {
    padding: 50px 75px 50px 75px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
