import React, { useState } from "react";
import { closeModal } from "@redq/reuse-modal";
import Input from "common/components/Input";
import Button from "common/components/Button";
import Box from "common/components/Box";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import Container from "common/components/UI/Container";
import Logo from "common/components/UIElements/Logo";

import LogoImageAlt from "common/assets/image/saasModern/CloudPouchLogo.png";

import { Form } from "./contactform.style";
const API_ENDPOINT = "https://web-api.cloudpouch.dev/formmobile";

const nameRegexPattern = /^[\p{L}][\p{L}\p{Zs}]*$/u;
const textRegexPattern = /^[a-zA-Z0-9\s\-\.,@!?&$()'"% \p{L}]*$/u;
const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validateName = (name) => {
  return nameRegexPattern.test(name);
};

const validateEmail = (email) => {
  return emailRegexPattern.test(email);
};

const validateMessage = (message) => {
  return textRegexPattern.test(message);
};

const CloseButton = () => (
  <Button
    className="modal-close-button"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const SuccessContent = () => (
  <Container>
    <CloseButton />
    <Box>
      <Text
        as="span"
        display="block"
        textAlign="center"
        fontSize="14px"
        letterSpacing="0.15em"
        fontWeight="700"
        color="#7197d6"
        mb="1rem"
        content="REQUEST SUCCESSFULL ✅"
      />
      <Heading
        textAlign="center"
        fontSize={["20px", "24px"]}
        fontWeight="500"
        color="#0f2137"
        letterSpacing="-0.025em"
        mb="0"
        lineHeight="1.67"
        content="You will receive the download link soon."
      />
    </Box>
    <Logo
      href="/"
      logoSrc={LogoImageAlt}
      title="CloudPouch See. - Understand. Reduce AWS costs."
      logoStyle={{
        maxWidth: ["130px", "256px"],
        textAlign: "center",
      }}
      className="logo-alt"
      m="50px"
    />
  </Container>
);

const FailureContent = () => (
  <Container>
    <CloseButton />
    <Box>
      <Text
        as="span"
        display="block"
        textAlign="center"
        fontSize="14px"
        letterSpacing="0.15em"
        fontWeight="700"
        color="#cb3d4a"
        mb="1rem"
        content="REQUEST FAILED ❌"
      />
      <Heading
        textAlign="center"
        fontSize={["20px", "24px"]}
        fontWeight="500"
        color="#0f2137"
        letterSpacing="-0.025em"
        mb="0"
        lineHeight="1.67"
        content="Something happened. Please try again later!"
      />
    </Box>
    <Logo
      href="/"
      logoSrc={LogoImageAlt}
      title="CloudPouch See. - Understand. Reduce AWS costs."
      logoStyle={{
        maxWidth: ["130px", "256px"],
        textAlign: "center",
      }}
      className="logo-alt"
      m="50px"
    />
  </Container>
);

const RequestTrialContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [nameInvalid, setNameInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [messageInvalid, setMessageInvalid] = useState(false);

  const handleNameChange = (value) => {
    setName(value);
    setNameInvalid(!validateName(value));
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    setEmailInvalid(!validateEmail(value));
  };

  const handleMessageChange = (value) => {
    setMessage(value);
    setMessageInvalid(!validateMessage(value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setNameInvalid(!validateName(name));
    setEmailInvalid(!validateEmail(email));
    setMessageInvalid(!validateMessage(message));

    if (validateName(name) && validateEmail(email)) {
      const data = {
      name,
      email,
      message,
      };

      try {
        const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
            "Content-Type": "application/json",
      },
          body: JSON.stringify(data),
        });

        const res = await response.json();
        console.log(res);
        console.log("Success:", res);
        // Reset form fields
        setName("");
        setEmail("");
        setMessage("");
        setNameInvalid(false);
        setEmailInvalid(false);
        setMessageInvalid(false);
        setSubmitted(true);
      } catch (error) {
        console.error(error);
        setFailed(true);
      }
    }
  };

  if (submitted) return <SuccessContent />;
  if (failed) return <FailureContent />;

  return (
    <>
      <Container>
        <CloseButton />
        <Box>
          <Text
            as="span"
            display="block"
            textAlign="center"
            fontSize="14px"
            letterSpacing="0.15em"
            fontWeight="700"
            color="#7197d6"
            mb="1rem"
            content="Thank you for your interest!"
          />
          <Heading
            textAlign="center"
            fontSize={["17px"]}
            fontWeight="500"
            color="#0f2137"
            letterSpacing="-0.025em"
            mb="5px"
            lineHeight="1.67"
            content="CloudPouch is only available for desktop computers."
          />
          <Heading
            textAlign="center"
            fontSize={["15px"]}
            fontWeight="500"
            color="#0f2137"
            letterSpacing="-0.025em"
            mb="10px"
            lineHeight="1.67"
            content="Leave your email below, and we'll send you a download link. Thank you!"
          />
          <Form onSubmit={handleSubmit}>
            <Input
              onChange={handleNameChange}
              label="Name"
              inputType={"text"}
              value={name}
              isInvalid={nameInvalid}
              invalidText={"Please enter a valid name"}
            />
            <Input
              onChange={handleEmailChange}
              label="Email Address"
              inputType={"email"}
              value={email}
              isInvalid={emailInvalid}
              invalidText={"Please enter a valid email address"}
            />
            <Input
              onChange={handleMessageChange}
              label="Message"
              inputType={"textarea"}
              value={message}
              isInvalid={messageInvalid}
              invalidText={"Message contains invalid characters!"}
            />
            <Button
              title="Submit"
              fontSize="14px"
              fontWeight="600"
              borderRadius="4px"
              pt="0"
              pb="0"
              colors={"primaryWithBg"}
              type="submit"
            />
          </Form>
        </Box>
      </Container>
    </>
  );
};

export default RequestTrialContactForm;
