import React from 'react';
import { Icon } from 'react-icons-kit';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import { SectionHeader } from './appClassic.style';
import SectionWrapper, {
  PricingArea,
  InnerWrapper,
  PricingCard,
  PricingEnterpriseCard,
} from './pricingPolicy.style';

import crown from 'common/assets/image/appClassic/crown.svg';

const PricingPolicy = () => {
  const pricingPlans = [
    {
      id: 1,
      title: 'Unlimited License',
      description: 'Single payment',
      price: 679,
      paddleId: 836622,
      period: 'once',
      trail: 7,
      trailLink: '#',
      features: [
        {
          id: 0,
          text: 'Single payment for life',
        },
        {
          id: 1,
          text: 'Limitless AWS Accounts',
        },
        {
          id: 2,
          text: "CloudPouch desktop app",
        },
        {
          id: 3,
          text: 'macOS, Windows, Linux',
        },
        {
          id: 4,
          text: 'Automatic updates',
        },
        {
          id: 5,
          text: 'Best option for experts',
        },
        {
          id: 6,
          text: 'Video Call or email support',
        },
      ],
    },
    {
      id: 2,
      title: 'Yearly Subscription',
      description: 'Best price',
      suggested: true,
      price: 399,
      paddleId: 836623,
      period: 'yearly',
      trail: 7,
      trailLink: '#',
      features: [
        {
          id: 0,
          text: 'Billed yearly. You save $69',
        },
        {
          id: 1,
          text: 'Limitless AWS Accounts',
        },
        {
          id: 2,
          text: "CloudPouch desktop app",
        },
        {
          id: 3,
          text: 'macOS, Windows, Linux',
        },
        {
          id: 4,
          text: 'Automatic updates',
        },
        {
          id: 5,
          text: '15% discount',
        },
        {
          id: 6,
          text: 'Email support.',
        },
      ],
    },
    {
      id: 3,
      title: 'Monthly Subscription',
      description: 'Elastic commitment',
      suggested: false,
      period: 'monthly',
      price: 39,
      paddleId: 836624,
      trail: 7,
      features: [
        {
          id: 0,
          text: 'Billed monthly',
        },
        {
          id: 1,
          text: 'Limitless AWS Accounts',
        },
        {
          id: 2,
          text: "CloudPouch desktop app",
        },
        {
          id: 3,
          text: 'macOS, Windows, Linux',
        },
        {
          id: 4,
          text: 'Automatic updates',
        },
        {
          id: 5,
          text: 'Small monthly payment.',
        },
        {
          id: 6,
          text: 'Email support.',
        },
      ],
    },
  ]

  const openPaddleCheckout = (planId) => {
    console.log('openPaddleCheckout');
    let Paddle = window['Paddle'];
    if (Paddle) {
      console.log('Paddle is here. Selected plan = ', planId);
      Paddle.Checkout.open({ product: planId });
    }
  }

  return (
    <SectionWrapper id="pricing">
      <Container>
        <SectionHeader>
          <Heading as="h5" content="PRICING PLAN" />
          <Heading content="No billing surprises. Fixed-price for subscription or single payment. " />
        </SectionHeader>
        <center>
          <PricingEnterpriseCard>
            <Text textAlign="center" content={<>
              Are you representing a company? Do you need more licenses?<br />
              Our <b>Enterprise Plan covers 100 users for only 639 USD</b> monthly.<br />
              Please <a href="https://www.linkedin.com/in/pawelzubkiewicz/" target='_blank'>contact Paweł Zubkiewicz via LinkedIn</a> for more details.
            </>} />
          </PricingEnterpriseCard>
        </center>
        <PricingArea>
          <InnerWrapper>
            {pricingPlans.map((item) => (
              <PricingCard key={`active-card--key${item.id}`}>
                {item.suggested && (
                  <span className="tag">
                    <img src={crown} alt="Crown" /> Suggested
                  </span>
                )}
                <div className="card-header">
                  <Heading as="h3" content={item.title} />
                  <Text content={item.description} />
                </div>
                <div className="card-body">
                  <ul className="feature-list">
                    {item.features.map((item) => (
                      <li key={`active-feature--key${item.id}`}>
                        <Icon icon={checkmarkCircled} /> {item.text}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="card-footer">
                  <strong>
                    <span>${item.price}</span> /{item.period}
                  </strong>
                  <Button title="Buy Now" {...buttonFillStyle} onClick={() => openPaddleCheckout(item.paddleId)} />
                  <div className="trail">
                    <a>Credit card or PayPal</a>
                  </div>
                </div>
              </PricingCard>
            ))}
          </InnerWrapper>
          <Text {...smallTextStyle} content="🛡 Payments are safely processed and invoiced by a Paddle.com company.  💳  Depending on your country laws tax may be added to your purchase." />
        </PricingArea>
      </Container>
    </SectionWrapper>
  );
};

export default PricingPolicy;


const buttonFillStyle = {
  type: 'button',
  fontSize: '14px',
  fontWeight: '600',
  color: 'white',
  borderRadius: '4px',
  pl: '10px',
  pr: '10px',
  colors: 'primaryWithBg',
  width: '200px',
  maxWidth: '100%',
}

const smallTextStyle = {
  fontSize: ['12px', '14px', '14px', '14px', '14px'],
  lineHeight: '1.75',
  mb: '0',
  mt: '5px',
  textAlign: 'center',
}