import React from 'react';
import PropTypes from 'prop-types';
import IframeResizer from 'iframe-resizer-react'
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import SectionHeader from './appClassic.style';

const ShoutoutTestimonials = ({
  sectionWrapper
}) => {

  return (
    <Box {...sectionWrapper} as="section" id="testimonial_section">
      <Container>
        <SectionHeader>
          <Heading as="h5" content="What our clients say about us" />
          <Heading content="Endorsed by the AWS community" />
        </SectionHeader>
        <Box>
          <IframeResizer
            src='https://shoutout.io/embed/CloudPouch/'
            scrolling={"yes"}
            width={"100%"}
            style= {{
              border: 0
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

ShoutoutTestimonials.propTypes = {
  sectionHeader: PropTypes.object,
};

ShoutoutTestimonials.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '100px'],
    pb: ['10px', '10px', '10px', '10px', '10px'],
  }
};

export default ShoutoutTestimonials;
